import logo from './logo.svg';
import './App.scss';
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import BackGroundMusic from "./BackGroundMusic";
import React from "react";

function App() {


        const audioRef = React.useRef(null);
        const [isPlaying, setIsPlaying] = React.useState(false);

        const togglePlayPause = () => {
            const audio = audioRef.current;
            if (audio.paused) {
                audio.play();
                setIsPlaying(true);
            } else {
                audio.pause();
                setIsPlaying(false);
            }
        };

        const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText('CHXqW2ghC3PEqUWPQx1wVKYnmnuzFRjJjK2sXRgtpump');
            console.log('copy')
        } catch (error) {
            console.log('error')
        }
    };

    return (

        <div className="App">
            <audio ref={audioRef} src="/ytmp3-convert.com_192kbps-gta-4-loading-screen-theme-hd.mp3" />

            <div className='header'><div></div><h1>$BORIS</h1>  <div className='susa'><img src='/img_17.png'/> <img src={isPlaying ? '/pause-button.png' : 'music-note.png'} onClick={togglePlayPause}/></div>

            </div>
            <div className='FP'>
              <img className='Cat' src='cat 4.png'/>


                <div className='FPB'>
                    <h1>Meet Boris</h1>
                    <p>From Mafia Boss to Cryptocurrency</p>
                  <a href='https://t.me/borissolana'> <button>Discover $BORIS</button></a>

                </div>


            </div>
            <div className='SP'>
                <div className='SPB'>
                <img className='money1' src='img_6.png'/>    <h1>Boris</h1>
                    <p>Russian Mafia leader who stole all the money to fund his dream of creating a meme coin.</p>
                    <div className='SPBIcons'>
                        <a href='https://dexscreener.com/solana/CHXqW2ghC3PEqUWPQx1wVKYnmnuzFRjJjK2sXRgtpump'>   <img src='img_2.png'/></a>
                        <a href='https://www.pump.fun/CHXqW2ghC3PEqUWPQx1wVKYnmnuzFRjJjK2sXRgtpump'>     <img src='img_3.png'/></a>
                        <a href='https://t.me/borissolana'>    <img src='img_4.png'/></a>
            </div>

                </div>
                <div className='zoom-container'> <img className='front' src='cat11.png'/> <img className='zoom-image' src='back11.png'/></div>



            </div>
            <div className='TP'>
                <h1>Tokenomics</h1> <img className='money2' src='img_6.png'/>
               <img className='money3' src='img_7.png'/>
                <div className='TPB'>
                <div className='FirstCol'>
                    <div className='TPItem'><div className='hhru'>Token address:<img onClick={copyToClipboard} src='img_16.png'/></div>
                        <span>CHXqW2ghC3PEqUWPQx1wV</span>
                        <span>KYnmnuzFRjJjK2sXRgtpump</span>

                    </div>
                    <div className='TPItem'>Token Supply: 1.000.000.000</div>
                </div>
                <div className='SecondCol'>
                    <div className='TPItem'>Burned 100%</div>
                    <div className='TPItem'>Cute 0%</div>
                </div>
                </div>
            </div>

            <div className='FFP'>

                <div className='zoom-container'> <img className='front' src='cat 6.png'/> <img className='zoom-image' src='back 6.png'/></div>


                <div className='FFPB'>
                    <h1>His ass is not cute!</h1>
                    <div>
                    <p>He is angry ASF!!</p>
                    <p> Because someone believes his token</p>
                        <p>doesn't have potential to grow</p>
                        <p> Fix it ASAP</p></div>
                   <a href='https://t.me/borissolana'><button>Make him happy</button></a>


                </div>


            </div>
            <div className='FFFP'>
           <img className='ak1' src='img_8.png'/>     <h1>About</h1> <img className='ak2' src='img_9.png'/>
                <p>In the past, Boris the cat was the boss of the Russian mafia. He stole all the money and decided to create his own cryptocurrency.
                    If you don't want to upset Boris the cat, recognize his dream that
                    the coin could rise in value.</p>
                <div className='FFFPImages'>


                    <div className='zoom-container'> <img className='front' src='cat 1.png'/> <img className='zoom-image' src='back 1.png'/></div>
                    <div className='zoom-container'> <img className='front' src='cat22.png'/> <img className='zoom-image' src='back22.png'/></div>
                    <div className='zoom-container'> <img className='front' src='cat 3.png'/> <img className='zoom-image' src='back 3.png'/></div>


                    <img src=''/><img src=''/>

                </div>


            </div>
            <h1 className='kos'>How to help boris?</h1>
            <div className='SSP'>

                <div className='SSPBox'>

<img className='div4' src='img_15.png'/>
                    <div className='div1'> <p>1. Create a Phantom Wallet</p><img src='img_12.png'/></div>
                    <div className='div2'><p>2. Buy SOLANA</p><img src='img_13.png'/></div>
                    <div className='div3'><p>3. Swap SOLANA - $BORIS</p><img src='img_14.png'/></div>
                </div>
                <div className='zoom-container'> <img className='front' src='cat 6.png'/> <img className='zoom-image' src='back 6.png'/></div>


            </div>
            <div className='footer'><h1>$BORIS</h1>
            <p>Please note that Boris Coin is a purely meme token with no intrinsic financial value. No guarantees are provided regarding the potential growth or decline of the token. Boris Coin is intended solely for entertainment purposes</p>
            </div>
        </div>
    );
}

export default App;
